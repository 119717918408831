<template>
    <div v-if="!loading">
        <zw-table ref="table"
                  tableName="users"
                  columnsPrefix="users.column."
                  :items="myProvider"
                  :fields="fields"
                  :params="params"
                  :filter="filter"
                  :selected="selected"
                  :sticky="false"
                  sticky-header="100%"
                  :actions-list="getActions()"
        >
            <template #cell(id)="row">
                <template v-if="row.item._edit">
                    <a @click="$root.$children[0].openModal('user-modal', {id:row.item.id,reset:row.item._reset}, refreshTable, {width: '800px'})"
                       href="#">{{ row.item.id }}</a>
                </template>
                <template v-else>
                    {{ row.item.id }}
                </template>
            </template>
            <template #cell(actions)="row">

                <b-dropdown class="mb-1"
                            left
                            variant="primary"
                            size="sm"
                >
                    <template #button-content>
                        {{ $t('common.label.actions') }}
                    </template>

                    <b-dropdown-item v-if="row.item._edit"
                                     @click="$root.$children[0].openModal('user-modal', {id:row.item.id,reset:row.item._reset}, refreshTable, {width: '800px'})">
                        <font-awesome-icon class="mr-2" icon="edit"/>
                        {{ $t('common.title.edit') }}
                    </b-dropdown-item>
                    <b-dropdown-item v-if="row.item._delete"
                                     @click="deleteUser(row.item.id)">
                        <font-awesome-icon class="mr-2" icon="trash"/>
                        {{ $t('common.title.delete') }}
                    </b-dropdown-item>
                </b-dropdown>
            </template>
        </zw-table>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'SettingsUsers',
    mixins: [commonTable, commonSave],
    data() {
        return {
            loading: true,
            saveFilters: true
        }
    },
    methods: {
        ...mapGetters('Users', ['getUsersTable']),
        shown() {
            this.loading = false
            this.$root.afterSettingsUpdate()
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Users/fetchUsers', 'getUsersTable')
        },
        deleteUser(id) {
            this.$removeConfirm('Users/deleteUser', id, this.refreshTable)
        },
        getActions() {
            let actions = [
                {
                    title: this.$t('common.button.create'),
                    icon: 'plus',
                    hide: !this.$root.hasAccess('users', 'create'),
                    click: () => {
                        this.$root.$children[0].openModal('user-modal', {id: 0}, this.refreshTable, {width: '800px'})
                    }
                }
            ]
            return actions
        }
    },
    mounted() {
        this.shown()
    }
}
</script>